// material-ui
import { Box, useMediaQuery } from '@mui/material';
// import { LoginOutlined } from '@ant-design/icons';IconButton,
//import { useAuth0 } from '@auth0/auth0-react';

// project import
import Search from './Search';
import Profile from '../../../../views/Profile';

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
    const matchesXs = useMediaQuery((theme) => theme.breakpoints.down('md'));
    //const { loginWithRedirect } = useAuth0();

    return (
        <>
            {!matchesXs && <Search />}
            {matchesXs && <Box sx={{ width: '100%', ml: 1 }} />}
            {/* <IconButton onClick={() => loginWithRedirect()} sx={{ color: 'text.primary', bgcolor: 'grey.100' }}>
                <LoginOutlined />
            </IconButton> */}
            {!matchesXs && <Profile />}
        </>
    );
};

export default HeaderContent;
