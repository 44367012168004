import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
//import * as serviceWorker from "./serviceWorker";
import { Auth0Provider } from '@auth0/auth0-react';
import history from './utils/history';
import { getConfig } from './config';

// scroll bar
import 'simplebar/src/simplebar.css';

// third-party
import { Provider as ReduxProvider } from 'react-redux';

// apex-chart
import 'assets/third-party/apex-chart.css';

// project import
import App from './App';
import { store } from 'store';
import reportWebVitals from './reportWebVitals';
import { CookiesProvider } from "react-cookie";

const onRedirectCallback = (appState) => {
    history.push(appState && appState.returnTo ? appState.returnTo : window.location.pathname);
};

const config = getConfig();

const providerConfig = {
    domain: config.domain,
    clientId: config.clientId,
    audience: config.audience,
    redirectUri: window.location.origin,
    onRedirectCallback

};

ReactDOM.render(
    <StrictMode>
        <ReduxProvider store={store}>
            <BrowserRouter basename="">
                <Auth0Provider {...providerConfig}>
                    <CookiesProvider>
                        <App />
                    </CookiesProvider>
                </Auth0Provider>
            </BrowserRouter>
        </ReduxProvider>
    </StrictMode>,
    document.getElementById('root')
);

reportWebVitals();
