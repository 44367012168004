import PropTypes from 'prop-types';
import { useRef, useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Box,
    ButtonBase,
    CardContent,
    ClickAwayListener,
    Grid,
    IconButton,
    Paper,
    Popper,
    Stack,
    // Tab,
    // Tabs,
    Typography
} from '@mui/material';

// project import
import MainCard from 'components/MainCard';
import Transitions from 'components/@extended/Transitions';

// assets
import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
//import { LogoutOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons';
// import ProfileTab from 'layout/MainLayout/Header/HeaderContent/ProfileTab';
// import SettingTab from 'layout/MainLayout/Header/HeaderContent/SettingTab';

// tab panel wrapper
function TabPanel({ children, value, index, ...other }) {
    return (
        <div role="tabpanel" hidden={value !== index} id={`profile-tabpanel-${index}`} aria-labelledby={`profile-tab-${index}`} {...other}>
            {value === index && children}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
};

// function a11yProps(index) {
//     return {
//         id: `profile-tab-${index}`,
//         'aria-controls': `profile-tabpanel-${index}`
//     };
// }

// ==============================|| HEADER CONTENT - PROFILE ||============================== //

const Profile = () => {
    const theme = useTheme();
    const { user, isAuthenticated, logout, getAccessTokenSilently } = useAuth0();
    const [userMetadata, setUserMetadata] = useState(null);

    const handleLogout = async () => {
        logout({ returnTo: window.location.origin });
    };

    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    // const [value, setValue] = useState(0);

    // const handleChange = (event, newValue) => {
    //     setValue(newValue);
    // };

    const iconBackColorOpen = 'grey.300';

    // useEffect(() => {
    //     const getUserMetadata = async () => {
    //         const domain = "dev-wfxm6z4z.us.auth0.com";
    //         try {
    //             const accessToken = await getAccessTokenSilently();
    //             console.log(accessToken);
    //             const userDetailsByIdUrl = `https://${domain}/api/v2/users/${user.sub}`;

    //             const metadataResponse = await fetch(userDetailsByIdUrl, {
    //                 headers: {
    //                     Authorization: `Bearer ${accessToken}`,
    //                 },
    //             });

    //             const { user_metadata } = await metadataResponse.json();

    //             setUserMetadata(user_metadata);
    //             console.log(user_metadata);
    //         } catch (e) {
    //             console.log(e.message);
    //         }
    //     };

    //     getUserMetadata();
    // }, [getAccessTokenSilently, user?.sub]);
    // useEffect(() => {
    //     (async () => {

    //         try {
    //             const token = await getAccessTokenSilently({
    //                 audience: 'https://dev-wfxm6z4z.us.auth0.com/api/v2/',
    //                 scope: 'read:current_user',
    //             });
    //             const userDetailsByIdUrl = `https://dev-wfxm6z4z.us.auth0.com/api/v2/users/${user.sub}`;
    //             const response = await fetch(userDetailsByIdUrl, {
    //                 headers: {
    //                     Authorization: `Bearer ${token}`,
    //                 },
    //             });
    //             setUserMetadata(await response.json());
    //         } catch (e) {
    //             console.error(e);
    //         }
    //     })();
    // }, [getAccessTokenSilently]);

    return (
        isAuthenticated && (
            <Box sx={{ flexShrink: 0, ml: 0.75 }}>
                <ButtonBase
                    sx={{
                        p: 0.25,
                        bgcolor: open ? iconBackColorOpen : 'transparent',
                        borderRadius: 1,
                        '&:hover': { bgcolor: 'secondary.lighter' }
                    }}
                    aria-label="open profile"
                    ref={anchorRef}
                    aria-controls={open ? 'profile-grow' : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                >
                    <Stack direction="row" spacing={2} alignItems="center" sx={{ p: 0.5 }}>
                        <UserOutlined style={{ fontSize: '22px' }} />
                        <Avatar alt={user.name} src={user.picture} />
                        <Typography>{user.name}</Typography>
                    </Stack>
                </ButtonBase>
                <Popper
                    placement="bottom-end"
                    open={open}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    transition
                    disablePortal
                    popperOptions={{
                        modifiers: [
                            {
                                name: 'offset',
                                options: {
                                    offset: [0, 9]
                                }
                            }
                        ]
                    }}
                >
                    {({ TransitionProps }) => (
                        <Transitions type="fade" in={open} {...TransitionProps}>
                            {open && (
                                <Paper
                                    sx={{
                                        boxShadow: theme.customShadows.z1,
                                        width: 320,
                                        minWidth: 240,
                                        maxWidth: 320,
                                        [theme.breakpoints.down('md')]: {
                                            maxWidth: 250
                                        }
                                    }}
                                >
                                    <ClickAwayListener onClickAway={handleClose}>
                                        <MainCard elevation={0} border={false} content={false}>
                                            <CardContent sx={{ px: 2.5, pt: 3 }}>
                                                <Grid container justifyContent="space-between" alignItems="center">
                                                    <Grid item>
                                                        <Stack direction="row" spacing={1.25} alignItems="center">
                                                            <Avatar alt={user.name} src={user.picture} />
                                                            <Stack>
                                                                <Typography>{user.name}</Typography>
                                                                <Typography variant="body2" color="textSecondary">
                                                                    {/* {user.email} */}
                                                                </Typography>
                                                            </Stack>
                                                        </Stack>
                                                    </Grid>
                                                    <Grid item>
                                                        <IconButton size="large" color="secondary" onClick={handleLogout}>
                                                            <LogoutOutlined />
                                                        </IconButton>
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                            {open && (
                                                <>
                                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                                        {/* <Tabs
                                                        variant="fullWidth"
                                                        value={value}
                                                        onChange={handleChange}
                                                        aria-label="profile tabs"
                                                    >
                                                        <Tab
                                                            sx={{
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                textTransform: 'capitalize'
                                                            }}
                                                            icon={<UserOutlined style={{ marginBottom: 0, marginRight: '10px' }} />}
                                                            label="Profile"
                                                            {...a11yProps(0)}
                                                        />
                                                        <Tab
                                                            sx={{
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                textTransform: 'capitalize'
                                                            }}
                                                            icon={<SettingOutlined style={{ marginBottom: 0, marginRight: '10px' }} />}
                                                            label="Setting"
                                                            {...a11yProps(1)}
                                                        />
                                                    </Tabs> */}
                                                    </Box>
                                                    {/* <TabPanel value={value} index={0} dir={theme.direction}>
                                                    <ProfileTab handleLogout={handleLogout} />
                                                </TabPanel>
                                                <TabPanel value={value} index={1} dir={theme.direction}>
                                                    <SettingTab />
                                                </TabPanel> */}
                                                </>
                                            )}
                                        </MainCard>
                                    </ClickAwayListener>
                                </Paper>
                            )}
                        </Transitions>
                    )}
                </Popper>
            </Box>
        )
    );
};

export default Profile;
