// assets
import { CalendarOutlined } from '@ant-design/icons';

// icons
const icons = {
    CalendarOutlined
};

// ==============================|| MENU ITEMS - EXTRA PAGES ||============================== //

const branchOffices = {
    id: 'branchOffices',
    title: 'Sucursales',
    type: 'group',
    children: [
        {
            id: 'agendaVinedos',
            title: 'Agenda Viñedos',
            type: 'item',
            url: '/agendaVinedos',
            //url: '/calendar',
            icon: icons.CalendarOutlined,
            breadcrumbs: false
        },
        {
            id: 'agendaNavarro',
            title: 'Agenda Navarro',
            type: 'item',
            url: '/agendaNavarro',
            //url: '/calendar',
            icon: icons.CalendarOutlined,
            breadcrumbs: false
        }
        ,
        {
            id: 'agendaConstitucion',
            title: 'Agenda Constitucion',
            type: 'item',
            url: '/agendaConstitucion',
            //url: '/calendar',
            icon: icons.CalendarOutlined,
            breadcrumbs: false
        },
        {
            id: 'agendaRevolucion',
            title: 'Agenda Revolución',
            type: 'item',
            url: '/agendaRevolucion',
            //url: '/calendar',
            icon: icons.CalendarOutlined,
            breadcrumbs: false
        }
    ]
};

export default branchOffices;