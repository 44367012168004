import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';

// project import
import Routes from 'routes';
import ThemeCustomization from 'themes';
import ScrollTop from 'components/ScrollTop';
import { withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from 'components/Loading';
import { UserInfo } from 'components/UserInfo';

// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //

const App = () => (
    <ThemeCustomization>
        <ScrollTop>
            <Routes />
        </ScrollTop>
    </ThemeCustomization>
);

//export default App;

export default withAuthenticationRequired(App, {
    onRedirecting: () => <Loading />,
});
