// assets
import { DashboardOutlined, CalendarOutlined, TeamOutlined, UnorderedListOutlined, HomeOutlined, SettingOutlined } from '@ant-design/icons';

// icons
const icons = {
    DashboardOutlined,
    CalendarOutlined,
    TeamOutlined,
    UnorderedListOutlined,
    HomeOutlined,
    SettingOutlined
};

// ==============================|| MENU ITEMS - UTILITIES ||============================== //

// const utilities = {
//     id: 'utilities',
//     //title: 'Utilities',
//     type: 'group',
//     children: [
//         {
//             id: 'dashboard',
//             title: 'Inicio',
//             type: 'item',
//             url: '/dashboard/default',
//             icon: icons.DashboardOutlined,
//             breadcrumbs: false
//         },
//         {
//             id: 'agenda',
//             title: 'Agenda',
//             type: 'item',
//             url: '/agenda',
//             //url: '/calendar',
//             icon: icons.CalendarOutlined,
//             breadcrumbs: false
//         },
//         {
//             id: 'patients',
//             title: 'Pacientes',
//             type: 'item',
//             //url: '/patients',
//             url: '/sample-page',
//             icon: icons.TeamOutlined,
//             breadcrumbs: false
//         },
//         {
//             id: 'services',
//             title: 'Servicios',
//             type: 'item',
//             //url: '/services',
//             url: '/sample-page',
//             icon: icons.UnorderedListOutlined
//         },
//         {
//             id: 'branch',
//             title: 'Sucursales',
//             type: 'item',
//             //url: '/branch',
//             url: '/sample-page',
//             icon: icons.HomeOutlined
//         },
//         {
//             id: 'settings',
//             title: 'Configuración',
//             type: 'item',
//             //url: '/settings',
//             url: '/sample-page',
//             icon: icons.SettingOutlined
//         }
//     ]
// };

const utilities = {
    id: 'utilities',
    //title: 'Utilities',
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: 'Inicio',
            type: 'item',
            url: '/dashboard/default',
            icon: icons.DashboardOutlined,
            breadcrumbs: false
        },
        {
            id: 'agenda',
            title: 'Agenda',
            type: 'item',
            url: '/agenda',
            //url: '/calendar',
            icon: icons.CalendarOutlined,
            breadcrumbs: false
        }
    ]
};

export default utilities;
