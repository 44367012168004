// ==============================|| THEME CONFIG  ||============================== //
import configJson from './auth_config.json';

const config = {
    defaultPath: '/dashboard/default',
    fontFamily: `'Public Sans', sans-serif`,
    i18n: 'en',
    miniDrawer: false,
    container: true,
    mode: 'light',
    presetColor: 'default',
    themeDirection: 'ltr'
};

export default config;
export const drawerWidth = 260;
export const twitterColor = '#1DA1F2';
export const facebookColor = '#3b5998';
export const linkedInColor = '#0e76a8';

export function getConfig() {
    const audience = configJson.audience && configJson.audience !== 'YOUR_API_IDENTIFIER' ? configJson.audience : null;

    return {
        domain: configJson.domain,
        clientId: configJson.clientId,
        ...(audience ? { audience } : null)
    };
}

