import logo from 'assets/images/logo-app.jpg';

const Logo = () => {
    return (
        <>
            <img src={logo} alt="Pes de Seda" width="200" />
        </>
    );
};

export default Logo;
